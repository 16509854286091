import {
  Box,
  Center,
  Checkbox,
  Flex,
  Heading,
  Image,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { capitalize, isArray } from "lodash";
import React, { useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FaEnvelope, FaLock, FaUser } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom";
import * as yup from "yup";
import Button from "../../components/Button";
import CompanyPolicy from "../../components/CompanyPolicy/CompanyPolicy";
import { useRegisterUserMutation } from "../../redux/api/user/userRegisterSlice";
import Input, { InputPhone } from "../../components/Input";
import PasswordChecker, {
  PasswordCondition,
} from "../../components/PasswordChecker/PasswordChecker";
import Popup from "../../components/Popup/Popup";
import OTPScreen from "../auth/OtpScreen";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { trackEvent } from "../../utility/mix-panel";
import Logo2 from "../../components/Logo2";
import Logo from "../../components/Logo";
import SignUpImage from "../../assets/images/Sign up.png";
import SignUpBgImage from "../../assets/images/signupbg.png";
import CutStructLogo from "../../assets/images/cutstructlogo.png";

const UserSignup = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [userType, setUserType] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [isChecked, setChecked] = React.useState(false);
  const [goToEmailVerification, setGoToEmailVerification] =
    React.useState(false);

  const toast = useToast();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invitationId = queryParams.get("invitationId");
  const projectId = queryParams.get("projectId");

  const users = [
    { label: "Contractor", value: "BUILDER" },
    { label: "Vendor", value: "SUPPLIER" },
    // {label: "Project Sponsor", value: "FUND_MANAGER"},
  ];

  const registerUserSchema = yup.object({
    businessName: yup
      .string()
      .required("Business name is required")
      .test("businessname", "Business name cannot be empty", (value) => {
        return !/^\s*$/.test(value);
      })
      .test(
        "min-length",
        "Business name must be at least 3 characters long",
        (value) => {
          return value.trim().length >= 3;
        },
      ),
    name: yup
      .string()
      .required("Full name is required")
      .test("fullname", "Full name cannot be empty", (value) => {
        return !/^\s*$/.test(value);
      })
      .test(
        "min-length",
        "Full name must be at least 3 characters long",
        (value) => {
          return value.trim().length >= 3;
        },
      ),
    email: yup.string().required("Email is required").email(),
    userType: yup.object().required("This field is required"),
    password: yup
      .string()
      .required("Password is required")
      .test(
        "password",
        "Error! password does not conform with the security measures",
        (value) => {
          return /^(?=.*\d)(?=.*[~!@£#$%^&*()_\-+=,.<>?/|':;{}])[A-Za-z\d~!@£#$%^&*()_\-+=,.<>?/|':;{}]{8,}$/.test(
            value,
          );
        },
      ),
    phone: yup
      .string()
      .required("Phone number is required")
      .test("phone", "Phone number cannot be empty", (value) => {
        return !/^\s*$/.test(value);
      })
      .test(
        "min-length",
        "Phone number must be at least 11 characters long",
        (value) => {
          return value.trim().length >= 11;
        },
      ),
  });

  const methods = useForm({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      password: "",
      businessName: "",
    },
    resolver: yupResolver(registerUserSchema),
  });

  // 👇 Calling the User Register Mutation
  const [registerUserApi, { data, isLoading, isSuccess, error, isError }] =
    useRegisterUserMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const watchUserType = useWatch({ control, name: "userType" });

  useEffect(() => {
    trackEvent("signup_started", { userType: watchUserType?.label });
  }, [watchUserType]);

  useEffect(() => {
    const handleSuccess = () => {
      toast({
        description:
          "You have successfully registered kindly check your email for otp to verify your email",
        status: "success",
      });
      setLoading(false);
    };

    const handleError = (error) => {
      setLoading(false);
      const errorMessage = isArray(error?.data?.message)
        ? error?.data?.message[0]
        : error?.data?.message;

      toast({
        description: errorMessage,
        status: "error",
      });

      if (
        errorMessage ===
        "email not verified, kindly verify with the otp sent to your email"
      ) {
        setGoToEmailVerification(true);
      }
    };

    if (isSuccess) {
      const userData = {
        id: data.data.id,
        userId: data.data.id,
        businessName: capitalize(data.data.businessName),
        userName: capitalize(data.data.name),
        email: data.data.email,
        "Phone Number": data.data.phoneNumber,
        userType: userType,
        status: false,
      };

      handleSuccess();

      trackEvent("onboarding_started", {
        ...userData,
        acceptTerms: true,
        verifiedEmail: false,
      });
    }
    if (isError) {
      handleError(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onSubmit = (data) => {
    setLoading(true);
    registerUserApi({
      businessName: data.businessName,
      name: data.name,
      email: data.email.toLowerCase(),
      phoneNumber: data.phone,
      acceptTerms: true,
      password: data.password,
      userType: userType,
    });

    setPassword(data?.password);
    setEmail(data?.email);
  };

  const [isLessThan900] = useMediaQuery("(max-width: 900px)");
  const [isLessThan1310] = useMediaQuery("(max-width: 1310px)");

  return (
    <>
      {isSuccess || goToEmailVerification ? (
        <OTPScreen
          email={email}
          password={password}
          userType={userType}
          projectId={projectId}
          invitationId={invitationId}
        />
      ) : (
        <Flex w="100vw" h="full" direction={isLessThan900 ? "column" : "row"}>
          {!isLessThan900 && (
            <Box
              color="#ffffff"
              bgColor="#12355A"
              bgImage={SignUpBgImage}
              bgRepeat="no-repeat"
              bgSize="cover"
              w={isLessThan900 ? "100%" : "40%"}
              minH={isLessThan900 ? "200px" : "100vh"}
              h={isLessThan900 && "500px"}
              overflow="hidden"
            >
              <Flex
                direction="column"
                justify={"space-between"}
                p="50px 0 0"
                h={isLessThan900 ? "100%" : "100vh"}
              >
                <Box m="0 50px">
                  <Logo />
                </Box>
                <Box m="0px 50px">
                  <Box mt="40px">
                    <Text as="h2" fontWeight={600} fontSize={35}>
                      With Cutstruct Pro, you can:
                    </Text>
                    <ul>
                      <li style={{ margin: "10px 0", fontSize: "20px" }}>
                        Seamlessly create and manage construction projects
                        procurement all within one platform.
                      </li>
                      <li style={{ margin: "10px 0", fontSize: "20px" }}>
                        Requests for Quotation (RFQs) directly from the platform
                      </li>
                      <li style={{ margin: "10px 0", fontSize: "20px" }}>
                        Receive and compare bids from multiple suppliers to
                        secure the best deals.
                      </li>
                      <li style={{ margin: "10px 0", fontSize: "20px" }}>
                        Ensure quality control for materials delivered.
                      </li>
                      <li style={{ margin: "10px 0", fontSize: "20px" }}>
                        Gain detailed breakdowns of your project expenditure
                        through a unified dashboard.
                      </li>
                    </ul>
                  </Box>
                  <Image
                    src={SignUpImage}
                    w={isLessThan900 ? "65%" : "80%"}
                    m="auto"
                    alt="login"
                  />
                </Box>
              </Flex>
            </Box>
          )}
          <Box p={["0", "10"]} w={isLessThan900 ? "100%" : "60%"}>
            {isLessThan900 && (
              <Box m="30px 20px">
                <Logo2 />
              </Box>
            )}
            <Center
              bg="#ffffff"
              bgImage={CutStructLogo}
              bgSize="cover"
              bgRepeat="no-repeat"
              bgBlendMode="lighten"
              bgColor="rgba(255,255,255,.97)"
              // px="20px"
              minH="80vh"
            >
              <Box w={["90%", "90%", "90%", isLessThan1310 ? "80%" : "60%"]}>
                <>
                  <Heading color="#F5862E" fontSize={["24px", "34px"]}>
                    Sign Up
                  </Heading>
                  <Text color="#999999" my="10px">
                    Get access to state of the art construction support for your
                    projects.
                  </Text>{" "}
                  <Box mt="20px">
                    <hr />
                  </Box>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Box fontSize="14px" mt="30px" mb="10px">
                      <Box my={"15px"}>
                        <Text>
                          Who are you? <span style={{ color: "red" }}>*</span>
                        </Text>
                        <Controller
                          control={control}
                          name="userType"
                          render={({ field: { onChange, value } }) => (
                            <Box w={"100%"}>
                              <CustomSelect
                                value={value}
                                options={users}
                                onChange={(e) => {
                                  onChange(e);
                                  setUserType(e?.value);
                                }}
                              />
                              <div style={{ color: "red" }}>
                                {errors["userType"]
                                  ? errors["userType"]?.message
                                  : ""}
                              </div>
                            </Box>
                          )}
                        />
                      </Box>
                      <Box my={"15px"}>
                        <Text>
                          Business Name <span style={{ color: "red" }}>*</span>
                        </Text>
                        <Controller
                          control={control}
                          name="businessName"
                          render={({ field: { onChange, value } }) => (
                            <Box w={"100%"}>
                              <Input
                                placeholder="Business Name"
                                value={value}
                                onChange={onChange}
                              />
                              <div style={{ color: "red" }}>
                                {errors["businessName"]
                                  ? errors["businessName"]?.message
                                  : ""}
                              </div>
                            </Box>
                          )}
                        />
                      </Box>
                      <Box my={"15px"}>
                        <Text>
                          Name (business contact person)
                          <span style={{ color: "red" }}>*</span>
                        </Text>
                        <Controller
                          control={control}
                          name="name"
                          render={({ field: { onChange, value } }) => (
                            <Box w={"100%"}>
                              <Input
                                placeholder="Full Name"
                                value={value}
                                onChange={onChange}
                                leftIcon={<FaUser />}
                              />
                              <div style={{ color: "red" }}>
                                {errors["name"] ? errors["name"]?.message : ""}
                              </div>
                            </Box>
                          )}
                        />
                      </Box>
                      <Box my={"15px"}>
                        <Text>
                          Email Address (business contact person){" "}
                          <span style={{ color: "red" }}>*</span>
                        </Text>

                        <Controller
                          control={control}
                          name="email"
                          render={({ field: { onChange, value } }) => (
                            <Box w={"100%"}>
                              <Input
                                placeholder="yourname@example.com"
                                value={value}
                                onChange={onChange}
                                leftIcon={<FaEnvelope />}
                              />
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {errors["email"]
                                  ? errors["email"]?.message
                                  : ""}
                              </div>
                            </Box>
                          )}
                        />
                      </Box>
                      <Box my={"15px"}>
                        <Text>
                          Phone Number (business contact person){" "}
                          <span style={{ color: "red" }}>*</span>
                        </Text>
                        <Controller
                          name="phone"
                          control={control}
                          rules={{
                            required: true,
                            minLength: 7,
                          }}
                          render={({ field: { onChange, value } }) => (
                            <InputPhone value={value} onChange={onChange} />
                          )}
                        />

                        <div style={{ color: "red", fontSize: "14px" }}>
                          {errors["phone"] ? errors["phone"]?.message : ""}
                        </div>
                      </Box>

                      <Box my="20px">
                        <Flex justify="space-between">
                          <Text>
                            Password <span style={{ color: "red" }}>*</span>
                          </Text>
                          <Popup info={<PasswordCondition />}></Popup>
                        </Flex>
                        <Controller
                          name="password"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Box>
                              <Input
                                type={"password"}
                                value={value}
                                onChange={onChange}
                                placeholder="Enter your Password"
                                leftIcon={<FaLock />}
                              />

                              <div style={{ color: "red", fontSize: "14px" }}>
                                {errors["password"]
                                  ? errors["password"]?.message
                                  : ""}
                              </div>

                              <Box mt={3}>
                                <PasswordChecker control={control} />
                              </Box>
                            </Box>
                          )}
                        />
                      </Box>
                    </Box>

                    <Box>
                      <Flex gap={4}>
                        <Checkbox
                          isChecked={isChecked}
                          onChange={() => setChecked(!isChecked)}
                        />
                        <CompanyPolicy />
                      </Flex>
                      <Button
                        full
                        isSubmit
                        isLoading={loading}
                        disabled={!isChecked}
                      >
                        Create Account
                      </Button>

                      <Box textAlign="center" mt="20px">
                        Already have an account?{" "}
                        <Link to="/login">
                          <span
                            style={{
                              color: "#F5862E",
                              marginLeft: "5px",
                              fontWeight: "600",
                            }}
                          >
                            Login
                          </span>
                        </Link>
                      </Box>
                    </Box>
                  </form>
                </>
              </Box>
            </Center>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default UserSignup;
